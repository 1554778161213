@import '../color-variables.scss';

.NftCard {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #ffa800;
  height: auto;
  background: #252525;
  width: fit-content;

  &:hover {
    cursor: pointer;
    .NftCard__btn {
      display: flex;
    }
  }

  &__image {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
  }

  &__btn {
    display: none;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;

    & > div:first-child {
      padding: 8px 14px;
      background: var(--Orange-500, #ffa800);
      flex: 1;
      color: var(--Neutral-Blue-25, #f5f5ff);
      text-align: center;
      /* Action/lg */
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      text-transform: uppercase;
    }

    & > div:last-child {
      display: flex;
      padding: 8px 14px;
      background: var(--Orange-600, #c2860f);
    }
  }

  &__id {
    display: flex;
    padding: 12px 8px;
    text-align: center;
    color: #f5f5ff;
    font-family: 'SpaceMono-Regular', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
}
