@import '../color-variables.scss';

.banner {
  background: $Neutral-Blue-900;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 56px 40px 0 40px;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 560px) {
    padding: 56px 16px 0 16px;
  }

  &__top {
    min-height: 95vh;
    background-image: url('../../assets/icons/map-top-banner.png');
    background-size: contain;
    background-position: top;
    display: flex;
    width: 100%;
    max-width: 1440px;
    justify-content: center;

    @media screen and (max-width: 992px) {
      height: max-content;
      padding: 50px 0;
    }
  }

  &__group-btn {
    display: flex;
    gap: 6px;
    margin: 0 auto;
    margin-top: 60px;

    a {
      text-decoration: none;
    }

    @media screen and (max-width: 1120px) {
      margin-top: 80px;
    }

    @media screen and (max-width: 1050px) {
      margin-top: 140px;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-top: 40px;
    }
  }

  &__btn-burn {
    color: #2121ff;
    background: var(--Neutral-Blue-900, #000013);
    padding: 8px 6px;
    cursor: pointer;

    &--content {
      color: var(--Yellow-200, #fdfae7);
      text-align: center;
      text-overflow: ellipsis;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      overflow: hidden;
      white-space: nowrap;

      div:first-child {
        color: var(--Neutral-Blue-300, #666693);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      div:last-child {
        width: 284px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__content-top {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;

    &__image {
      position: relative;
    }

    &__cloud-center {
      position: absolute;
      bottom: -150px;
      left: -80px;
    }

    @media screen and (max-width: 1350px) {
      justify-content: flex-start;
      padding-top: 50px;
    }

    @media screen and (max-width: 992px) {
      padding-top: 0;
    }

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    img {
      width: 100%;
      max-width: 1030px;
    }

    &__detail {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin-top: -100px;

      @media screen and (max-width: 992px) {
        margin-top: -20px;
      }
    }

    &--text {
      color: #fff;
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      max-width: 640px;
      text-align: center;
      width: 100%;

      @media screen and (max-width: 992px) {
        max-width: 480px;
        font-size: 14px;
        left: 51%;
        text-transform: capitalize;
        top: 36%;
      }

      @media screen and (max-width: 767px) {
        position: relative;
        top: inherit;
        left: inherit;
        bottom: inherit;
        transform: inherit;
        text-align: center;
        max-width: 312px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  &__cloud-left {
    position: absolute;
    left: 0;
    top: 80px;
    opacity: 0.2;

    @media screen and (max-width: 992px) {
      left: -100px;
    }
  }

  &__cloud-center {
    position: absolute;
    left: 100px;
    top: 300px;
    opacity: 0.2;
  }

  &__cloud-right {
    position: absolute;
    right: 0;
    top: 200px;
    opacity: 0.2;

    @media screen and (max-width: 992px) {
      right: -100px;
    }
  }

  &__quote-left {
    position: absolute;
    top: 50px;
    left: -50px;

    @media screen and (max-width: 1350px) {
      left: 0;
      top: 0;
    }

    &.right {
      top: 180px;
      right: -180px;
      left: inherit;

      @media screen and (max-width: 1350px) {
        right: 0;
        top: -30px;
      }
    }

    img {
      width: 220px;

      @media screen and (max-width: 600px) {
        width: 130px;
      }
    }

    &__content {
      position: relative;
    }

    &__desc {
      position: absolute;
      bottom: 20px;
      left: 10px;

      @media screen and (max-width: 600px) {
        bottom: 12px;
        left: 5px;
      }

      & > div:first-child {
        color: var(--Neutral-Blue-900, #000013);
        text-align: center;
        font-family: 'SpaceMono-Regular', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        opacity: 0.5;

        @media screen and (max-width: 600px) {
          font-size: 7.13px;
          line-height: 9.507px;
        }
      }

      & > div:last-child {
        color: var(--Neutral-Blue-900, #000013);
        text-align: center;
        /* Body Small/Bold */
        font-family: 'SpaceMono-Bold', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 600px) {
          font-size: 8.319px;
          line-height: 10.695px;
          width: 120px;
        }
      }
    }
  }

  &__token-burn {
    display: flex;
    justify-content: center;
    position: relative;

    img {
      max-width: 520px;

      @media screen and (max-width: 992px) {
        max-width: 332px;
      }
    }

    &__desc {
      position: absolute;
      bottom: 10px;

      @media screen and (max-width: 992px) {
        bottom: 0;
      }

      @media screen and (max-width: 560px) {
        bottom: 10px;
      }

      & > div:first-child {
        color: var(--Neutral-Blue-0, #fff);
        text-align: center;
        text-shadow: 2px 2px 0 #000013;
        /* Body Large/Bold */
        font-family: 'SpaceMono-Bold', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;

        @media screen and (max-width: 992px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    &__number {
      color: var(--Neutral-Grey-50, #fafafa);
      text-align: center;
      text-shadow: -1px 4px 0px #470017;
      /* H4 */
      -webkit-text-stroke-width: 2;
      -webkit-text-stroke-color: var(--Pink-900, #470017);
      font-family: 'RetroGaming', sans-serif;
      font-size: 56px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 67.2px */
      letter-spacing: -0.56px;
      text-transform: uppercase;

      @media screen and (max-width: 992px) {
        font-size: 40px;
      }

      @media screen and (max-width: 560px) {
        font-size: 25.257px;
      }
    }
  }

  &__icon-down {
    margin: 0 auto;
    max-width: max-content;
    margin-top: 100px;

    @media screen and (max-width: 767px) {
      padding-top: 60px;
      margin-top: 0;
    }

    svg {
      animation: aniDropDown 2s ease infinite;
    }

    color: #fff;
    text-align: center;
    font-family: 'SpaceMono-Regular', sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 175% */
    letter-spacing: 0.08px;
    text-transform: uppercase;
  }

  &__content {
    background-image: url('../../assets/icons/map.png');
    background-position: top;
    padding: 0 40px 96px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    width: 100%;
    max-width: 1440px;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 992px) {
      padding: 48px 0;
    }

    img {
      width: 100%;
      max-width: 540px;
    }

    @media screen and (max-width: 992px) {
      gap: 28px;
    }
  }

  &__text {
    color: var(--Neutral-Blue-25, #f5f5ff);
    text-align: center;
    text-shadow: 0 18.951px 0 #130900;
    /* H5 */
    font-family: 'RetroGaming', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 38.4px */
    letter-spacing: -0.32px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 992px) {
      font-size: 18px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    @media screen and (max-width: 560px) {
      font-size: 16px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    span {
      color: $Yellow-500;
    }
  }

  &__btn {
    padding: 16px 24px;
    color: $Neutral-Blue-0;
    text-align: center;
    /* Action/Medium-16 */
    font-family: 'RetroGaming', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    border-radius: 8px;
    background: $Blue-500;
    width: max-content;
    cursor: pointer;
    position: relative;
    animation: bounce 1.3s infinite; /* Play with time and keyframes */

    @media screen and (max-width: 992px) {
      padding: 10px 24px;
      font-size: 14px;
      line-height: 20px;
    }

    @media screen and (max-width: 560px) {
      padding: 8px 20px;
      font-size: 12px;
      line-height: 16px;
    }

    &-icon-left {
      position: absolute;
      left: 5px;
      bottom: -2px;
    }

    &-icon-right {
      position: absolute;
      right: 5px;
      top: -3px;
    }
  }
}

.border-style-game {
  border-style: solid;
  border-width: 0.125em 0;
  box-shadow: -0.25em 0 0 -0.125em, 0.25em 0 0 -0.125em;
  margin: 0 0.375em;
  padding: 0.5em 0.25em;
  position: relative;
  z-index: 1;

  &:before {
    background-color: inherit;
    border: inherit;
    border-width: 0 0.125em;
    bottom: 0.25em;
    content: '';
    left: -0.375em;
    pointer-events: none;
    position: absolute;
    top: 0.25em;
    right: -0.375em;
    z-index: -1;
  }

  &:after {
    background: inherit;
    border: inherit;
    border-width: 0 0.125em;
    bottom: 0.125em;
    content: '';
    left: -0.25em;
    position: absolute;
    top: 0.125em;
    right: -0.25em;
    z-index: -2;
  }
}

.tvl {
  position: relative;
  z-index: 3;
  overflow: hidden;

  .banner__btn {
    margin: 0 auto;
    margin-top: 32px;
    display: flex;
    align-items: center;
    gap: 8px;

    @media screen and (max-width: 600px) {
      margin-top: -40px;
    }
  }

  &__line-break {
    background-image: url('../../assets/icons/break-tvl.png');
    width: 100%;
    height: 62px;

    @media screen and (max-width: 600px) {
      height: 30px;
      background-size: cover;
    }
  }

  &__cloud-left {
    position: absolute;
    left: 0;
    top: 0;

    @media screen and (max-width: 600px) {
      left: -50px;
      img {
        width: 174px;
      }
    }
  }

  &__cloud-right {
    position: absolute;
    right: 60px;
    top: 50px;

    @media screen and (max-width: 992px) {
      top: 18px;
      right: 0;
    }

    @media screen and (max-width: 600px) {
      right: -20px;
      img {
        width: 140px;
      }
    }
  }

  &__content {
    background: $Yellow-500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 96px 40px;
    position: relative;

    @media screen and (max-width: 600px) {
      padding: 50px 16px 220px 16px;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    &.mobile {
      display: none;
    }

    @media screen and (max-width: 992px) {
      &.mobile {
        display: block;
      }

      &.desktop {
        display: none;
      }
    }

    img {
      width: 100%;
      max-width: 720px;

      @media screen and (max-width: 992px) {
        max-width: 480px;
      }
    }

    &--main {
      color: $Neutral-Blue-0;
      text-align: center;
      text-shadow: 12px 12px 0 rgba(39, 39, 39, 0.15), 8px 8px 0 #000013,
        4px 4px 0 #000013;
      /* H1 */
      font-family: 'RetroGaming', sans-serif;
      font-size: 180px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }

    &--sub {
      color: var(--Neutral-Blue-25, #f5f5ff);
      /* H6 */
      font-family: 'RetroGaming', sans-serif;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 38.4px */
      text-transform: uppercase;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    margin: 32px 0;

    @media screen and (max-width: 560px) {
      margin: 0 0 24px 0;
    }

    img {
      width: 100%;
      max-width: 1248px;
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    max-width: 720px;
    margin: 0 auto;
    margin-bottom: 45px;

    color: $Neutral-Blue-900;
    text-align: center;
    /* Body Small/Regular */
    font-family: 'SpaceMono-Regular', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;

    @media screen and (max-width: 560px) {
      margin-bottom: 0;
    }
  }

  &__map-bottom {
    display: flex;
    justify-content: center;
  }

  &__buy-icon {
    position: absolute;
    right: -10%;
    bottom: -50px;
    z-index: 5;

    img {
      width: 189px;
    }

    @media screen and (max-width: 1300px) {
      right: -5%;
    }

    @media screen and (max-width: 600px) {
      right: auto;
      left: 40%;
      bottom: -180px;

      img {
        width: 164px;
      }
    }
  }

  &__monster {
    position: absolute;
    left: -15%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;

    @media screen and (max-width: 1300px) {
      left: -8%;
    }

    @media screen and (max-width: 992px) {
      top: 60%;
    }

    @media screen and (max-width: 600px) {
      top: auto;
      bottom: 350px;
      left: -20px;

      img {
        width: 50px;
      }
    }
  }

  &__how-to-buy {
    max-width: 1120px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 616px;
    position: relative;

    @media screen and (max-width: 992px) {
      flex-direction: column;
      height: auto;
      width: 100%;
    }

    @media screen and (max-width: 600px) {
      padding-bottom: 70px;
    }

    &-map {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;

      @media screen and (max-width: 992px) {
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
      }

      @media screen and (max-width: 600px) {
        img {
          margin-left: -16%;
        }
      }
    }

    &-line {
      width: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 992px) {
        display: none;
      }

      &-item {
        background: $Neutral-Blue-900;
        width: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: -1px 0;
        height: calc(100% - 18px);

        div {
          width: 3px;
          height: 15px;
          background-color: $Yellow-500;
          border-radius: 3px;
          margin-bottom: 3px;
        }
      }
    }

    &-line-mobile {
      justify-content: center;
      height: 14px;
      margin-top: -1px;
      display: none;

      @media screen and (max-width: 992px) {
        display: flex;
      }

      @media screen and (max-width: 600px) {
        display: none;
      }

      &-item {
        background: $Neutral-Blue-900;
        height: 14px;
        display: flex;
        align-items: center;
        width: calc(100% - 18px);

        div {
          height: 3px;
          width: 15px;
          background-color: $Yellow-500;
          border-radius: 3px;
          margin-right: 3px;
        }
      }
    }

    &-uniswap {
      border-radius: 0 16px 16px 0;
      width: 430px;
      background: $Neutral-Blue-900;
      padding: 16px 16px 16px 10px;
      position: relative;
      z-index: 3;

      &--content {
        background-color: $Neutral-Blue-900;
        border-radius: 20px;
        height: 100%;

        @media screen and (max-width: 992px) {
          height: 596px;
          max-width: 358px;
          margin: 0 auto;
        }

        @media screen and (max-width: 600px) {
          height: 553px;
          max-width: 100%;
          padding: 16px;
        }
      }

      @media screen and (max-width: 992px) {
        border-radius: 0 0 16px 16px;
        width: calc(100% - 32px);
      }

      @media screen and (max-width: 600px) {
        border-radius: 16px;
        margin-top: 24px;
        padding: 0;
        width: 100%;
      }
    }

    &--title {
      color: $Neutral-Blue-25;
      text-shadow: 0 2px 0 #2121ff;
      /* H4 */
      font-family: 'RetroGaming', sans-serif;
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 76.8px */
      letter-spacing: -0.64px;
      text-transform: uppercase;
      display: flex;
      gap: 4px;
      position: relative;
      z-index: 2;

      @media screen and (max-width: 992px) {
        padding: 40px 56px 0 56px;
      }

      @media screen and (max-width: 560px) {
        padding: 32px 24px 0 24px;
        font-size: 32px;
        align-items: center;
      }

      svg {
        padding-top: 15px;
        padding-left: 5px;
      }
    }

    &--address {
      color: $Neutral-Blue-900;
      text-align: center;
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      text-transform: capitalize;
      width: max-content;
      position: relative;
      z-index: 2;
      max-width: calc(100% - 48px);

      @media screen and (max-width: 992px) {
        margin: 0 56px;
      }

      @media screen and (max-width: 600px) {
        margin: 0 24px;
      }

      svg {
        cursor: pointer;
      }
    }
  }

  &__how-to-buy-content {
    padding: 40px 56px 40px 40px;
    border-radius: 16px 0 0 16px;
    background: $Neutral-Blue-900;
    width: 579px;

    @media screen and (max-width: 992px) {
      width: 100%;
      padding: 0;
      border-radius: 16px 16px 0 0;
    }

    @media screen and (max-width: 600px) {
      border-radius: 16px;
    }
  }

  &__address-content {
    display: flex;
    gap: 8px;
    background-color: $Neutral-Blue-0;
    padding: 0 16px;
    align-items: center;

    div:first-child {
      @media screen and (max-width: 600px) {
        word-break: break-all;
      }
    }
  }

  &__address-border.top {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;

    & > div:first-child {
      height: 3px;
      width: calc(100% - 18px);
      background-color: white;
    }
    & > div:nth-child(2) {
      height: 3px;
      width: calc(100% - 12px);
      background-color: white;
    }

    & > div:last-child {
      height: 3px;
      width: calc(100% - 6px);
      background-color: white;
    }
  }

  &__address-border.bottom {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;

    & > div:first-child {
      height: 3px;
      width: calc(100% - 6px);
      background-color: white;
    }
    & > div:nth-child(2) {
      height: 3px;
      width: calc(100% - 12px);
      background-color: white;
    }

    & > div:last-child {
      height: 3px;
      width: calc(100% - 18px);
      background-color: white;
    }
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    height: 390px;
    overflow-y: scroll;
    padding-right: 16px;

    &::-webkit-scrollbar {
      width: 6px;
      background: rgba(255, 255, 255, 0.05);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
      background-color: rgba(255, 255, 255, 0.1);
      cursor: pointer;
    }

    @media screen and (max-width: 1120px) {
      height: 320px;
    }

    @media screen and (max-width: 992px) {
      padding: 0 56px 40px 56px;
      height: 100%;
      overflow: hidden;
    }

    @media screen and (max-width: 600px) {
      padding: 0 24px 32px 24px;
    }
  }

  &__item {
    padding: 12px 16px;
    color: $Neutral-Blue-0;
    background: $Neutral-Blue-900;

    @media screen and (max-width: 600px) {
      padding: 4px;
    }
  }

  &__item-title {
    display: flex;
    color: $Neutral-Blue-0;
    font-family: 'SpaceMono-Bold', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    justify-content: space-between;
    cursor: pointer;

    .show {
      transform: rotate(180deg);
    }

    & > div:first-child {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  &__item-desc {
    color: $Neutral-Blue-0;
    /* Body XS/Regular */
    font-family: 'SpaceMono-Regular', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-left: 40px;

    @media screen and (max-width: 600px) {
      padding-bottom: 4px;
    }

    a {
      color: $Orange-500;
    }
  }
}

.tokenomics {
  position: relative;
  z-index: 2;
  margin-top: -1px;
  background: $Neutral-Blue-900;
  padding: 113px 0 96px 0;
  overflow: hidden;

  @media screen and (max-width: 992px) {
    padding: 96px 0 48px 0;
  }

  @media screen and (max-width: 560px) {
    padding: 56px 0 48px 0;
  }

  &__bg-top {
    background-image: url('../../assets/icons/break-token.png');
    position: absolute;
    height: 62px;
    width: 100%;
    left: 0;
    top: 0;

    @media screen and (max-width: 600px) {
      height: 30px;
      background-size: cover;
    }
  }

  &__content {
    position: relative;
  }

  &__infos {
    display: flex;
    gap: 24px;
    width: max-content;
    margin: 0 auto;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      width: calc(100% - 32px);
      margin: 0 16px;
    }
  }

  &__infos-item {
    background: $Neutral-Blue-900;
    color: $Blue-500;
    text-align: center;
    /* Body XLarge/Bold */
    font-family: 'SpaceMono-Regular', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding: 8px;
    height: 90px;
    width: 224px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 992px) {
      font-size: 18px;
      line-height: 24px;
      height: 90px;
      width: 170px;
    }

    @media screen and (max-width: 700px) {
      width: 93%;
      height: max-content;
    }

    .desc {
      color: var(--Neutral-Blue-200, #9999b3);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .tax {
      position: absolute;
      top: 0;
      left: -6px;
      border-radius: 2px;
      padding: 2px 8px 2px 10px;
      background: var(--Blue-500, #2121ff);
      color: var(--Neutral-Blue-0, #fff);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
    }

    div {
      color: $Neutral-Blue-0;
    }
  }

  &__number {
    color: var(--Pink-500, #ff3979);
    text-align: center;
    text-shadow: 0 6px 0 rgba(255, 255, 255, 0.15);
    /* H2 */
    font-family: 'RetroGaming', sans-serif;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 115.2px */
    letter-spacing: -0.96px;
    text-transform: uppercase;
    margin: 0 auto;
    margin-top: 16px;
    margin-bottom: 56px;
    max-width: 1328px;

    @media screen and (max-width: 992px) {
      font-size: 64px;
    }

    @media screen and (max-width: 560px) {
      font-size: 32px;
    }
    position: relative;
  }

  &__coin3 {
    position: absolute;
    right: 4%;
    bottom: -86%;

    @media screen and (max-width: 1200px) {
      right: 3%;
    }

    @media screen and (max-width: 992px) {
      img {
        width: 68px;
      }
    }

    @media screen and (max-width: 560px) {
      right: 40px;
      img {
        width: 40px;
      }
    }
  }

  &__coin2 {
    position: absolute;
    left: 4%;
    bottom: -90%;

    @media screen and (max-width: 1200px) {
      left: 3%;
    }

    @media screen and (max-width: 992px) {
      img {
        width: 68px;
      }
    }

    @media screen and (max-width: 768px) {
      left: 6%;
    }

    @media screen and (max-width: 560px) {
      left: 16px;
      img {
        width: 40px;
      }
    }
  }

  &__coin4 {
    position: absolute;
    top: -40px;
    right: 0;

    @media screen and (max-width: 992px) {
      img {
        width: 58px;
      }
    }

    @media screen and (max-width: 768px) {
      right: 20px;
    }

    @media screen and (max-width: 700px) {
      display: none;
    }
  }

  &__map-top {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1440px;

    img {
      width: 100%;
      max-width: 1440px;
    }
  }

  &__divider {
    display: flex;
    gap: 10px;
    margin: 0 auto;
    width: max-content;
    align-items: center;

    &-line {
      width: 274px;
      height: 1.6px;
      border: 2.527px solid $Neutral-Blue-900;
      background: $Neutral-Blue-25;

      @media screen and (max-width: 992px) {
        width: 120px;
      }

      @media screen and (max-width: 560px) {
        width: 40px;
      }
    }

    &-text {
      color: $Neutral-Blue-25;
      font-family: 'RetroGaming', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 38.4px */
      text-transform: uppercase;

      @media screen and (max-width: 560px) {
        font-size: 18px;
      }
    }
  }

  &__group-btn {
    display: flex;
    gap: 6px;
    margin-top: 40px;
    justify-content: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 0 16px;
      align-items: center;
    }

    a {
      text-decoration: none;
    }
  }

  &__btn-burn {
    color: #ff3979;
    background: var(--Pink-900, #470017);
    padding: 8px 6px;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      width: max-content;
    }

    &--content {
      color: var(--Pink-400, #ff6b9b);
      text-align: center;
      text-overflow: ellipsis;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      overflow: hidden;
      white-space: nowrap;

      div:first-child {
        color: var(--Pink-400, #ff6b9b);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      div:last-child {
        width: 284px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__desc {
    max-width: 720px;
    margin: 0 auto;
    margin-top: 56px;
    color: $Neutral-Blue-0;
    text-align: center;
    /* Body Small/Regular */
    font-family: 'SpaceMono-Regular', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;

    @media screen and (max-width: 992px) {
      padding: 0 40px;
    }

    @media screen and (max-width: 560px) {
      padding: 0 16px;
    }
  }

  &__title {
    text-align: center;
    max-width: 1328px;
    margin: 0 auto;
    position: relative;

    img {
      width: 100%;
      max-width: 728px;

      @media screen and (max-width: 992px) {
        max-width: 468px;
      }
    }
  }

  &__coin1 {
    position: absolute;
    left: 0;
    bottom: -60px;

    @media screen and (max-width: 992px) {
      left: 20px;
      bottom: -20px;
      img {
        width: 58px;
      }
    }

    @media screen and (max-width: 700px) {
      display: none;
    }
  }

  &__coin5 {
    position: absolute;
    top: 15%;
    right: 15%;

    @media screen and (max-width: 1200px) {
      right: 18%;
    }

    @media screen and (max-width: 992px) {
      img {
        width: 58px;
      }
    }

    @media screen and (max-width: 700px) {
      display: none;
    }
  }
}

.road-map {
  background: $Neutral-Blue-900;
  padding: 96px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 992px) {
    padding: 48px 0;
  }

  &__title {
    color: $Neutral-Blue-25;
    text-shadow: 0 2px 0 #2121ff;
    font-family: 'RetroGaming', sans-serif;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 115.2px */
    letter-spacing: -0.96px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 992px) {
      font-size: 64px;
    }

    @media screen and (max-width: 767px) {
      font-size: 32px;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    margin-top: 56px;
    padding: 0 96px;

    &.mobile {
      display: none;
    }

    @media screen and (max-width: 992px) {
      padding: 0 40px;
      margin-top: 26px;
    }

    @media screen and (max-width: 600px) {
      padding: 0 16px;

      &.mobile {
        display: block;
      }

      &.desktop {
        display: none;
      }
    }

    img {
      width: 100%;
      max-width: 1248px;
    }
  }

  &__icon-left {
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 600px) {
      left: -20px;
      top: 30px;

      img {
        width: 60px;
      }
    }
  }

  &__icon-right {
    position: absolute;
    top: 0;
    right: 0;

    @media screen and (max-width: 600px) {
      right: -20px;
      top: 30px;

      img {
        width: 60px;
      }
    }
  }
}

@keyframes bounce {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

@keyframes aniDropDown {
  0% {
    transform: translateY(-25px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-25px);
  }
}

.banner-advertisement {
  background-color: $Neutral-Blue-900;
  overflow: hidden;
  padding: 56px 0;

  @media screen and (max-width: 560px) {
    padding: 28px 0;
  }
}

.advertisement {
  position: relative;
  overflow: hidden;
  transform: rotate(-3deg);

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-left: 24px;

    @media screen and (max-width: 560px) {
      gap: 12px;
      margin-left: 12px;
    }

    @media screen and (max-width: 560px) {
      svg,
      img {
        width: 28px;
      }
    }
  }

  &__name {
    color: $Neutral-Blue-0;
    text-align: center;
    font-family: 'SpaceMono-Regular', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;

    @media screen and (max-width: 560px) {
      font-size: 16px;
    }
  }

  &__orange {
    height: 104px;
    background-color: $Neutral-Blue-900;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width: 560px) {
      height: 60px;
    }

    .advertisement__item {
      display: flex;
      align-items: center;
      gap: 24px;

      @media screen and (max-width: 560px) {
        gap: 12px;
      }
    }
  }
}

.swap {
  color: $Neutral-Blue-25;
  font-family: 'SpaceMono-Regular', sans-serif;

  &__title {
    font-size: 18px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: $Orange-500;
      text-decoration: none;
    }
  }

  &__setting {
    cursor: pointer;
  }

  &__box-input {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid $Neutral-Blue-25;
    color: $Neutral-Blue-25;

    &--label {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &--field {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &--right {
        display: flex;
        gap: 8px;
        height: max-content;
      }
    }

    input {
      font-size: 32px;
      background: transparent;
      border: 0;
      flex: 1;
      width: 200px;
      outline: none;
      color: $Neutral-Blue-0;
      font-family: 'SpaceMono-Regular', sans-serif;

      @media screen and (max-width: 991px) {
        width: 150px;
      }
    }
  }

  &__select-currency {
    padding: 5px 10px;
    border: 1px solid $Blue-500;
    border-radius: 8px;
    display: flex;
    gap: 8px;
    cursor: pointer;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }

    &-logo {
      display: flex;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__icon-down {
    margin: 10px auto;
    cursor: pointer;
    width: max-content;
  }

  &__btn-max {
    padding: 5px 10px;
    border: 1px solid $Blue-500;
    border-radius: 8px;
    color: $Blue-500;
    display: flex;
    cursor: pointer;
    align-items: center;
  }

  &__slippage,
  &__price {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  &__details {
    background-color: $Neutral-Blue-900;
    padding: 20px 20px;
    border-radius: 0 0 16px 16px;

    &.confirm {
      background-color: inherit;
      padding: 0;

      .swap__details--item {
        font-size: 12px;
      }
    }

    &--item {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;

      div:first-child {
        color: $Neutral-100;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__btn {
    margin-top: 30px;
    cursor: pointer;
    font-size: 16px;

    &--disabled {
      padding: 20px;
      text-align: center;
      border-radius: 16px;
      color: $Neutral-Blue-900;
      background-color: $Neutral-Blue-25;
      cursor: not-allowed;
      opacity: 0.5;
    }

    &-swap {
      background-color: $Blue-500;
      color: $Neutral-Blue-25;
      padding: 20px;
      text-align: center;
      border-radius: 16px;
      cursor: pointer;
    }
  }
}
