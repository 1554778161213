@import '../color-variables.scss';

.marketplace {
  margin-top: 56px;
  background: var(--Blue-800, #15154f);

  @media screen and (max-width: 560px) {
    margin-top: 48px;
  }

  &__banner {
    overflow: hidden;
    &__image {
      img {
        width: 100%;
        height: auto;
        max-height: 480px;
      }
    }

    &__logo {
      max-width: 1824px;
      margin: 0 auto;
      margin-top: -122px;
      padding: 0 48px;

      @media screen and (max-width: 768px) {
        padding: 0 16px;
      }

      @media screen and (max-width: 560px) {
        margin-top: -50px;

        img {
          width: 86px;
        }
      }
    }

    &__detail {
      display: flex;
      padding: 24px 48px;
      max-width: 1824px;
      margin: 0 auto;
      justify-content: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 24px;
        padding: 24px 16px;
      }

      & > div:first-child {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1;
      }
    }

    &__title {
      color: var(--Neutral-Blue-50, #f1f1f3);
      /* H5 */
      font-family: 'RetroGaming', sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 48px */
      letter-spacing: -0.4px;
      text-transform: uppercase;

      @media screen and (max-width: 560px) {
        font-size: 24px;
      }
    }

    &__desc {
      color: var(--Neutral-Blue-100, #ccccd3);
      /* Body Small/Regular */
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;

      span {
        color: var(--Neutral-Blue-25, #f5f5ff);
        /* Body Medium/Bold */
        font-family: 'SpaceMono-Bold', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }

    &__follow {
      width: 103px;
      color: var(--Neutral-Blue-300, #666693);
      /* Action/md */
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      text-transform: uppercase;
    }

    &__link {
      display: flex;
      gap: 16px;
      margin-top: 10px;

      a {
        color: #f5f5ff;
      }
    }
  }

  &__list-nft {
    padding: 0 48px;
    padding-bottom: 96px;
    max-width: 1824px;
    margin: 0 auto;
    margin-top: 24px;

    @media screen and (max-width: 768px) {
      padding: 0 16px;
    }
  }

  &__filter {
    display: flex;
    gap: 16px;
    z-index: 2;
    position: relative;

    margin-bottom: 24px;

    &__filter-btn {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: var(--Spacings-spacing-lg, 12px);
      border-radius: var(--Radii-radius-moderate, 8px);
      border: 1px solid var(--Neutral-Blue-400, #333374);
      background: var(--Neutral-Blue-700, #00003b);
      backdrop-filter: blur(20px);
      color: var(--Neutral-Blue-0, #fff);
      /* Action/lg */
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      text-transform: uppercase;
      cursor: pointer;
      width: 91px;
      position: relative;

      @media screen and (max-width: 768px) {
        display: none;

        &.mobile {
          position: fixed;
          right: 16px;
          bottom: 120px;
          width: auto;
          display: flex;
          z-index: 100;
        }
      }

      @media screen and (min-width: 769px) {
        &.mobile {
          display: none;
        }
      }
    }

    &__total {
      position: absolute;
      width: 16px;
      height: 16px;
      right: 10px;
      top: -8px;
      overflow: hidden;
      color: var(--Neutral-Blue-0, #fff);
      text-overflow: ellipsis;
      /* Body XS/Bold */
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      border-radius: 14px;
      background: var(--Orange-500, #ffa800);
      line-height: 16px;
      text-align: center;
    }

    &__search {
      flex: 1;

      input {
        width: calc(100% - 24px);
        outline: none;
        padding: 14px 12px;
        border-radius: 8px;
        border: 1px solid var(--Neutral-Blue-400, #333374);
        background: var(--Neutral-Blue-700, #00003b);
        backdrop-filter: blur(20px);
        overflow: hidden;
        color: var(--Neutral-Blue-0, #fff);
        text-overflow: ellipsis;
        /* Body Medium/Regular */
        font-family: 'SpaceMono-Regular', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.8px;

        &::placeholder {
          color: var(--Neutral-Blue-300, #666693);
        }
      }
    }

    &__sort {
      position: relative;
      &__btn {
        display: flex;
        width: 216px;
        justify-content: space-between;
        padding: 12px;
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid var(--Neutral-Blue-400, #333374);
        background: var(--Neutral-Blue-700, #00003b);
        backdrop-filter: blur(20px);
        overflow: hidden;
        color: var(--Neutral-Blue-300, #666693);
        text-overflow: ellipsis;
        /* Body Medium/Regular */
        font-family: 'SpaceMono-Regular', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.8px;

        @media screen and (max-width: 450px) {
          width: 140px;

          & > div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        span {
          color: var(--Neutral-Blue-0, #fff);
          text-overflow: ellipsis;
          /* Action/lg */
          font-family: 'SpaceMono-Bold', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          text-transform: uppercase;
        }
      }

      &__menu {
        position: absolute;
        top: calc(100% + 8px);
        right: 0;
        width: 240px;
        border-radius: var(--Radii-radius-moderate, 8px);
        background: var(--Neutral-Blue-900, #000013);
        backdrop-filter: blur(7.5px);
        padding: var(--spacing-3xs, 4px);
        display: flex;
        flex-direction: column;
        gap: 4px;

        &-item {
          color: var(--Neutral-Blue-0, #fff);
          /* Body XS/Regular */
          font-family: 'SpaceMono-Regular', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          padding: 10px 8px;

          &.active,
          &:hover {
            cursor: pointer;
            border-radius: 4px;
            background: var(--Overlay-W-5, rgba(255, 255, 255, 0.05));
            color: var(--Neutral-Blue-200, #9999b3);
          }
        }
      }
    }
  }

  &__content {
    max-width: 1824px;
    margin: 0 auto;
    z-index: 1;
    position: relative;
    display: flex;

    &--left {
      margin-right: 24px;

      &.hide {
        width: 0;
        overflow: hidden;
        margin-right: 0;
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    &--right {
      flex: 1;
    }
  }

  &__total-result {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-wrap: wrap;
  }

  &__total {
    color: var(--Neutral-Blue-200, #9999b3);
    /* Body Small/Bold */
    font-family: 'SpaceMono-Bold', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }

  &__tag {
    border-radius: var(--Radii-radius-moderate, 8px);
    padding: 8px;
    background: var(--Neutral-Blue-400, #333374);
    backdrop-filter: blur(20px);
    display: flex;
    gap: 8px;
    overflow: hidden;
    color: var(--Neutral-Blue-0, #fff);
    text-overflow: ellipsis;
    /* Body Small/Regular */
    font-family: 'SpaceMono-Regular', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    align-items: center;

    & > div {
      cursor: pointer;
      display: flex;
    }
  }

  &__list {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
      margin-bottom: 96px;
    }

    @media screen and (max-width: 560px) {
      justify-content: center;
    }

    &.full {
      .marketplace__nft {
        width: calc((100% - 74px) / 5);

        @media screen and (max-width: 1440px) {
          width: calc((100% - 56px) / 4);
        }

        @media screen and (max-width: 992px) {
          width: calc((100% - 22px) / 2);
          max-width: 343px;
        }

        @media screen and (max-width: 560px) {
          width: 100%;
          max-width: 343px;
        }
      }
    }

    &.hide {
      .marketplace__nft {
        width: calc((100% - 56px) / 4);

        @media screen and (max-width: 1440px) {
          width: calc((100% - 38px) / 3);
        }

        @media screen and (max-width: 992px) {
          width: calc((100% - 22px) / 2);
          max-width: 343px;
        }

        @media screen and (max-width: 560px) {
          width: 100%;
        }
      }
    }
  }

  &__nft {
    border-radius: 12px;
    border: 1px solid var(--Neutral-Blue-400, #333374);
    background: var(--Neutral-Blue-700, #00003b);
    overflow: hidden;

    cursor: pointer;
    position: relative;

    &:hover {
      .marketplace__nft__btn {
        display: flex;
      }
    }

    img {
      width: 100%;
    }

    &__detail {
      padding: 12px 12px 24px 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }

    &__name {
      color: var(--Neutral-Blue-25, #f5f5ff);
      text-align: center;
      /* Body Large/Bold */
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    &__price {
      border-radius: 8px;
      padding: 8px 12px;
      gap: 4px;
      border: 1px solid var(--Neutral-Blue-400, #333374);
      color: var(--Neutral-Grey-50, #fafafa);
      /* Body Medium/Bold */
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;

      span {
        color: var(--Neutral-Blue-0, #fff);
        /* Body XS/Regular */
        font-family: 'SpaceMono-Regular', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        opacity: 0.7;
      }
    }

    &__btn {
      display: none;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;

      &.sold {
        display: flex;
        & > div:first-child {
          background: var(--Orange-700, #93660b);

          span {
            opacity: 0.5;
          }
        }
      }

      & > div:first-child {
        padding: 16px 20px;
        background: var(--Orange-500, #ffa800);
        flex: 1;
        color: var(--Neutral-Blue-25, #f5f5ff);
        text-align: center;
        /* Action/lg */
        font-family: 'SpaceMono-Bold', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
        text-transform: uppercase;
      }

      & > div:last-child {
        display: flex;
        padding: 16px 20px;
        background: var(--Orange-600, #c2860f);
      }
    }
  }

  &__group-filter {
    border-radius: var(--Radii-radius-moderate, 8px);
    border: 1px solid var(--Neutral-Blue-400, #333374);
    background: var(--Neutral-Blue-700, #00003b);
    padding: 16px 0 16px 16px;

    &__content {
      width: 228px;
      height: 720px;
      overflow-y: auto;
      padding-right: 16px;
      margin-right: 4px;

      &::-webkit-scrollbar {
        border-radius: var(--corner-radius-circular, 100px);
        background: var(--Overlay-W-5, rgba(255, 255, 255, 0.05));
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: var(--corner-radius-circular, 100px);
        background: var(--Overlay-W-10, rgba(255, 255, 255, 0.1));
        width: 6px;
      }
    }
  }

  &__filter-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    &__name {
      color: var(--Neutral-Blue-0, #fff);
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      svg {
        transform: rotate(-90deg);
      }
    }

    &__options {
      display: flex;
      flex-direction: column;
      padding-left: 12px;
      gap: 16px;
    }

    &__option {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div:first-child {
        display: flex;
        gap: 8px;
        align-items: center;
        color: var(--Neutral-Blue-0, #fff);
        /* Body Small/Regular */
        font-family: 'SpaceMono-Regular', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        cursor: pointer;
      }

      & > div:last-child {
        color: var(--Neutral-Blue-0, #fff);
        /* Body 2XSmall/Bold */
        font-family: 'SpaceMono-Bold', sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
        opacity: 0.5;
      }
    }
  }
}
