@import '../color-variables.scss';

.modal {
  .ReactModal__Overlay {
    -webkit-backdrop-filter: blur(7.5px);
  }

  .ReactModal__Content {
    border-radius: 16px !important;
    background: $Neutral-900 !important;
    border: 0 !important;
    position: relative;
  }

  &__point {
    border-radius: 3.654px;
    background: rgba(115, 39, 0, 0.8);
    color: var(--Neutral-Blue-0, #fff);
    /* Body XLarge/Bold */
    font-family: 'SpaceMono-Bold', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding: 0 7.307px;
    margin-top: -40px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;

    &-title {
      color: $Neutral-0;
      text-align: center;
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      text-transform: uppercase;
    }

    &-description {
      color: $Neutral-0;
      text-align: center;
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      display: flex;
      gap: 8px;
      width: 100%;
      word-break: break-word;
      justify-content: center;

      &--error {
        max-height: 200px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          border-radius: var(--corner-radius-circular, 100px);
          background: var(--Overlay-W-5, rgba(255, 255, 255, 0.05));
          width: 6px;
          padding-left: 8px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: var(--corner-radius-circular, 100px);
          background: var(--Overlay-W-10, rgba(255, 255, 255, 0.1));
          width: 6px;
        }
      }

      a {
        color: $Blue-400;
        display: flex;
        text-decoration: unset;
      }
    }
  }

  &__icon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
  }

  &__list-btn {
    display: flex;
    gap: 10px;
  }

  &__btn-outline {
    border-radius: 8px;
    border: 1px solid var(--Neutral-Blue-200, #9999b3);
    padding: 8px 16px;
    color: $Neutral-Blue-25;
    text-align: center;
    /* Action/md */
    font-family: 'SpaceMono-Bold', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    text-transform: uppercase;
    cursor: pointer;
  }

  &__btn-action {
    padding: 8px 16px;
    border-radius: 8px;
    background: var(--Orange-500, #ffa800);
    color: $Neutral-Blue-25;
    text-align: center;
    /* Action/md */
    font-family: 'SpaceMono-Bold', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    text-transform: uppercase;
    cursor: pointer;
  }
}

.modal-activity {
  .modal__content-title {
    margin-bottom: 0;
    font-size: 18px;
  }

  .activity {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 0;
    border-bottom: 1px solid $Overlay-W-5;
    align-items: center;

    &__btn {
      border-radius: 8px;
      border: 1px solid var(--Neutral-Blue-200, #9999b3);
      padding: 8px 12px;
      color: $Neutral-Blue-25;
      text-align: center;
      /* Action/sm */
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      text-transform: uppercase;
      cursor: pointer;
    }

    &__info {
      display: flex;
      gap: 8px;
      align-items: center;

      &-method {
        color: $Neutral-Blue-25;
        font-family: 'SpaceMono-Bold', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
      }

      &-status {
        color: var(--Green-500, #72d84e);
        font-family: 'SpaceMono-Bold', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        text-transform: capitalize;

        &.completed {
          color: $Green-500;
        }

        &.pending {
          color: $Orange-500;
        }
      }

      &-txh {
        a {
          color: $Blue-400;
          text-align: center;
          text-overflow: ellipsis;
          font-family: 'SpaceMono-Bold', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px; /* 133.333% */
          text-transform: uppercase;
          text-decoration: unset;
          display: flex;
          align-items: center;
        }
      }
    }

    &__info-icon {
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: $Overlay-15;

      svg {
        color: $Neutral-0;
      }
    }
  }
}

.modal-deposit {
  &__tab {
    border-radius: 8px;
    background: var(--Overlay-5, rgba(255, 255, 255, 0.05));
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 4px;
    width: fit-content;
    margin: 0 auto;

    div {
      color: $Neutral-0;
      text-align: center;
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      text-transform: uppercase;
      width: 134px;
      padding: 6px 8px;
      border-radius: 8px;
      cursor: pointer;

      &.active,
      &:hover {
        background: var(--Overlay-25, rgba(255, 255, 255, 0.25));
      }
    }
  }

  &__logo {
    display: flex;
    margin: 0 auto;

    svg:first-child,
    svg:last-child {
      width: 40px;
      height: 40px;
    }
  }

  &__detail {
    display: flex;
    margin: 0 auto;
    left: -15px;
    position: relative;
    margin-top: 4px;
  }

  &__from {
    color: $Neutral-100;
    text-align: center;
    font-family: 'SpaceMono-Regular', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    &:last-child {
      margin-left: 70px;
      left: 16px;
      position: relative;
    }

    div:last-child {
      padding: 4px 8px;
      border-radius: 4px;
      background: $Overlay-15;
    }
  }

  &__form {
    width: 100%;
    &-label {
      color: $Neutral-0;
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;

      span {
        color: #e83636;
      }
    }
  }

  &__input {
    border-radius: 8px;
    border: 1px solid $Overlay-15;
    background: $Neutral-900;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    position: relative;

    &-info {
      display: flex;
      align-items: center;
    }

    &-available {
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      background: $Overlay-15;
      padding: 24px 8px 12px 8px;
      color: var(--Neutral-0, #f5f5ff);
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      opacity: 0.5;
      margin-top: -16px;
      z-index: 1;
      position: relative;
    }

    input {
      background: transparent;
      border: 0;
      outline: none;
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $Neutral-0;
      width: 100%;
    }

    &-symbol {
      color: $Neutral-0;
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      padding: 4px 8px;
      border-right: 1px solid $Overlay-15;
      margin-right: 8px;
    }
  }

  &__btn-max {
    border-radius: 4px;
    background: $Overlay-15;
    padding: 4px 8px;
    color: $Neutral-0;
    font-family: 'SpaceMono-Regular', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    height: 18px;
    cursor: pointer;
  }

  &__btn {
    color: var(--Neutral-0, #fffdf1);
    text-align: center;
    font-family: 'SpaceMono-Bold', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    border-radius: 8px;
    padding: 8px 14px;
    background: $Orange-500;
    width: max-content;
    margin: 0 auto;
    cursor: pointer;

    &.disabled {
      background: $Overlay-15;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.modal-select-token {
  color: $Neutral-0;
  font-family: 'SpaceMono-Regular', sans-serif;

  &__input-search {
    width: 100%;

    input {
      width: -webkit-fill-available;
      font-size: 16px;
      background: transparent;
      border: 1px solid $Neutral-Blue-25;
      outline: none;
      border-radius: 8px;
      height: 40px;
      padding-left: 15px;
      color: $Neutral-0;
      font-family: 'SpaceMono-Regular', sans-serif;
    }
  }

  &__list-currency {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 20px;
    height: 400px;
    overflow-y: auto;
  }

  &__currency {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    cursor: pointer;

    &.selected {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:hover {
      background-color: $Overlay-15;
      border-radius: 8px;
    }

    & > div:first-child {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    span {
      font-weight: 700;
    }

    &--logo {
      display: flex;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.modal__slippage {
  color: $Neutral-Blue-25;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
  font-family: 'SpaceMono-Regular', sans-serif;

  &-options {
    display: flex;
    gap: 8px;

    input {
      font-size: 15px;
      background: transparent;
      color: $Neutral-Blue-25;
      text-align: right;
      outline: none;
      border: 0;
      width: 90%;
      font-family: 'SpaceMono-Regular', sans-serif;
    }
  }

  &-input {
    height: 35px;
    border: 1px solid $Blue-500;
    border-radius: 10px;
    padding: 0 5px;
    display: flex;
    align-items: center;
  }

  &-option {
    border-radius: 10px;
    padding: 5px 10px;
    border: 1px solid $Blue-500;
    cursor: pointer;
    width: 50px;
    text-align: center;

    &.selected {
      background-color: $Blue-500;
    }
  }
}

.modal-connect-wallet {
  .wallet {
    display: flex;
    gap: 20px;
    color: $Neutral-Blue-25;
    border-radius: 12px;
    width: 300px;
    border: 1px solid $Neutral-Blue-700;
    align-items: center;
    padding: 10px 20px;
    justify-content: space-between;
    font-family: 'SpaceMono-Regular', sans-serif;

    &:hover {
      border-color: $Blue-500;
      cursor: pointer;
    }

    img {
      width: 30px;
    }
  }

  .box-wallet {
    border: 1px solid $Blue-500;
    width: 100%;
    border-radius: 12px;
    color: $Neutral-Blue-25;
    font-family: 'SpaceMono-Regular', sans-serif;

    &__content {
      padding: 10px 20px;
      font-size: 14px;
    }

    .address {
      font-size: 16px;
      margin-top: 10px;
      display: flex;
      gap: 10px;

      svg {
        cursor: pointer;
      }
    }

    .disconnect {
      margin-top: 10px;
      color: $Pink-500;
      margin-left: auto;
      cursor: pointer;
      width: max-content;
    }
  }
}

.modal-confirm-swap {
  color: $Neutral-Blue-25;
  font-family: 'SpaceMono-Regular', sans-serif;
  outline: none !important;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__token {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &--left {
      display: flex;
      align-items: center;
      gap: 10px;

      & > div:first-child {
        display: flex;
      }
    }
  }
}

.modal-buy-nft {
  width: 596px !important;
  outline: none !important;

  @media screen and (max-width: 768px) {
    width: calc(100% - 32px) !important;
    padding: 16px !important;
    height: calc(100vh - 48px) !important;
    top: 48px !important;
    transform: translateX(-50%) !important;

    &.ReactModal__Content {
      border-radius: 0 !important;
      padding-top: 48px !important;
      background: var(--Neutral-Blue-900, #000013) !important;
    }

    .modal__content {
      height: calc(100vh - 48px - 48px);
    }
  }

  &__detail {
    display: flex;
    gap: 16px;
    width: 100%;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      overflow-y: auto;
      padding-bottom: 202px;
    }

    img {
      width: 236px;
      height: 236px;
      border-radius: 8px;

      @media screen and (max-width: 768px) {
        width: 100%;
        max-width: 343px;
        height: auto;
        margin: 0 auto;
      }
    }
  }

  &__trail {
    border-radius: var(--Radii-radius-moderate, 8px);
    background: var(--Overlay-W-5, rgba(255, 255, 255, 0.05));
    padding: 16px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    height: max-content;
    width: max-content;

    @media screen and (max-width: 768px) {
      width: auto !important;
    }

    & > div:last-child {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }

    &__title {
      color: var(--Neutral-Blue-300, #666693);
      /* Body XS/Regular */
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    &__item {
      padding: 8px;
      border-radius: 4px;
      border-bottom: 1px solid var(--Overlay-W-5, rgba(255, 255, 255, 0.05));
      background: var(--Overlay-W-5, rgba(255, 255, 255, 0.05));

      & > div:first-child {
        /* Body XS/Regular */
        font-family: 'SpaceMono-Regular', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: var(--Yellow-600, #c2a40f);

        & > span {
          color: var(--Neutral-Blue-0, #fff);
          opacity: 0.5;
          padding-right: 8px;
        }
      }

      & > div:last-child {
        color: var(--Neutral-Blue-0, #fff);
        /* Body Small/Regular */
        font-family: 'SpaceMono-Regular', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  &__info {
    width: calc(100% - 32px);
    padding: 16px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    border-radius: var(--Radii-radius-moderate, 8px);
    background: var(--Overlay-W-5, rgba(255, 255, 255, 0.05));

    @media screen and (max-width: 768px) {
      position: fixed;
      bottom: 64px;
      left: 0;
      background: var(--Overlay-D-25, rgba(0, 0, 0, 0.25));
      backdrop-filter: blur(7.5px);
      padding: 16px;
    }
  }

  &__name {
    color: var(--Neutral-Blue-25, #f5f5ff);
    /* Body XLarge/Bold */
    font-family: 'SpaceMono-Bold', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  &__price {
    color: var(--Neutral-Grey-50, #fafafa);
    /* Body XLarge/Bold */
    font-family: 'SpaceMono-Bold', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;

    & > div:first-child {
      color: var(--Neutral-Grey-500, #999);
      /* Body Small/Regular */
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }

  &__btn-buy {
    padding: 16px 24px;
    border-radius: 8px;
    background: var(--Orange-500, #ffa800);
    color: var(--Neutral-Blue-0, #fff);
    text-align: center;
    /* Action/Decor-lg */
    font-family: 'RetroGaming', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-transform: uppercase;
    cursor: pointer;
  }
}

.modal-list-nft {
  @media screen and (max-width: 768px) {
    &.ReactModal__Content {
      background: var(--Neutral-Blue-900, #000013) !important;
    }

    .modal__content {
      height: calc(100vh - 48px - 48px);
    }
  }

  &__detail {
    display: flex;
    width: 100%;
    gap: 24px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      overflow-y: auto;
      padding-bottom: 176px;
    }

    img {
      width: 228px;
      height: 228px;
      border-radius: 8px;

      @media screen and (max-width: 768px) {
        width: 100%;
        max-width: 343px;
        height: auto;
        margin: 0 auto;
      }
    }
  }

  &__form {
    border-radius: var(--Radii-radius-moderate, 8px);
    background: var(--Overlay-W-5, rgba(255, 255, 255, 0.05));
    width: max-content;
    flex: 1;

    @media screen and (max-width: 768px) {
      width: 100%;
      background: transparent;
    }

    &__content {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media screen and (max-width: 768px) {
        padding: 0;
      }
    }
  }

  &__info {
    & > div:first-child {
      color: var(--Neutral-Blue-0, #fff);
      /* Body XS/Regular */
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      opacity: 0.5;
    }

    & > div:last-child {
      color: var(--Neutral-Blue-25, #f5f5ff);
      /* Body XLarge/Bold */
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
  }

  &__box-input {
    border-radius: var(--Radii-radius-moderate, 8px);
    border: 1px solid var(--Neutral-Blue-300, #666693);
    padding: 10px 16px;

    &__label {
      color: var(--Neutral-Blue-200, #9999b3);
      /* Body 3XSmall/Regular */
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;

      span {
        color: var(--Pink-500, #ff3979);
      }
    }

    & > div:last-child {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div:last-child {
        color: var(--Neutral-Blue-200, #9999b3);
        /* Body XS/Regular */
        font-family: 'SpaceMono-Regular', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }

    input {
      background: transparent;
      border: 0;
      outline: none;
      color: var(--Neutral-Blue-0, #fff);
      /* Action/Decor-lg */
      font-family: 'RetroGaming', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      text-transform: uppercase;
      width: 220px;
    }
  }

  &__rotalty-fee {
    display: flex;
    justify-content: space-between;

    & > div:first-child {
      color: var(--Neutral-Blue-200, #9999b3);
      /* Body 2XSmall/Regular */
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }

    & > div:last-child {
      color: var(--Neutral-Blue-0, #fff);
      text-align: right;
      /* Body XS/Regular */
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__noti {
    color: var(--Orange-500, #ffa800);
    /* Body 2XSmall/Regular */
    font-family: 'SpaceMono-Regular', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }

  &__total-earning {
    display: flex;
    justify-content: space-between;
    color: var(--Neutral-Blue-200, #9999b3);
    text-align: right;
    /* Body XS/Regular */
    font-family: 'SpaceMono-Regular', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 8px 0;
    border-bottom: 1px solid #ffffff0d;
    border-top: 1px solid #ffffff0d;
    margin-top: 8px;

    span {
      color: var(--Neutral-Blue-0, #fff);
      text-align: right;
      /* Body XS/Bold */
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      padding-right: 4px;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (max-width: 768px) {
      position: fixed;
      bottom: 64px;
      left: 0;
      background: var(--Overlay-D-25, rgba(0, 0, 0, 0.25));
      backdrop-filter: blur(7.5px);
      padding: 16px;
      width: calc(100% - 32px);
    }
  }
}

.modal-filter {
  padding: 0 !important;
  border: 0 !important;
  outline: none !important;

  &.ReactModal__Content {
    background: transparent !important;
  }
  .marketplace__group-filter__content {
    height: 60vh;
    width: auto;
  }

  &__icon-close {
    margin-top: 12px;
    margin-left: auto;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--Radii-radius-moderate, 8px);
    border: 1px solid var(--Neutral-Blue-400, #333374);
    background: var(--Neutral-Blue-700, #00003b);
    backdrop-filter: blur(20px);
    cursor: pointer;
  }
}
