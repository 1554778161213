@import '../color-variables.scss';

.MyNftPage {
  min-height: calc(100vh - 140px * 2);
  padding: 140px 0;
  background: #15154f;
  display: flex;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  align-items: center;

  &__title {
    color: #f5f5ff;
    text-shadow: 0px 2px 0px #2121ff;
    font-family: 'RetroGaming', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 76.8px */
    letter-spacing: -0.64px;
    text-transform: uppercase;
  }

  &__nft-box {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    max-width: 1248px;

    @media screen and (max-width: 1200px) {
      max-width: 700px;
    }
  }

  @media screen and (max-width: 637px) {
    &__title {
      font-size: 32px;
      font-weight: 400;
      line-height: 38.4px;
      letter-spacing: -0.32px;
    }

    &__nft-box {
      gap: 8px;
      max-width: 400px;
    }
  }
}
