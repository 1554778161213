@import '../color-variables.scss';

.app-pagination {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 20px 0;
  justify-content: flex-end;

  li {
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: $Neutral-Blue-25;
    font-family: 'SpaceMono-Bold', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;

    a {
      padding: 0 5px;
      min-width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.selected {
      color: $Neutral-Blue-25;
      background: $Blue-500;
      border: 1px solid $Blue-500;
    }

    &:hover {
      background: $Blue-500 !important;
      color: $Neutral-Blue-25;
      border: 1px solid $Blue-500;
    }

    &.disabled {
      color: rgba(255, 255, 255, 0.25);
      cursor: not-allowed;

      &:hover {
        background: inherit !important;
        border: 1px solid rgba(255, 255, 255, 0.25);
      }

      a {
        cursor: not-allowed;
      }
    }

    &.previous,
    &.next {
      background-color: transparent;
    }
  }
}
