@import '../color-variables.scss';

.Post {
  background: $Yellow-500;
  padding: 96px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  gap: 56px;

  @media screen and (max-width: 992px) {
    padding: 48px 0;
    height: calc(100vh - 48px * 2);
  }

  &__cloud-left {
    position: absolute;
    left: 0;
    top: 0;

    @media screen and (max-width: 600px) {
      left: -50px;
      img {
        width: 174px;
      }
    }
  }

  &__cloud-right {
    position: absolute;
    right: 60px;
    top: 50px;

    @media screen and (max-width: 992px) {
      top: 18px;
      right: 0;
    }

    @media screen and (max-width: 600px) {
      right: -20px;
      img {
        width: 140px;
      }
    }
  }

  &__title img {
    width: 549px;

    @media screen and (max-width: 992px) {
      width: 549px;
    }

    @media screen and (max-width: 767px) {
      width: 350px;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    overflow-y: hidden;
    overflow-x: unset;
    position: relative;

    @media screen and (max-width: 768px) {
      gap: 8px;
    }

    &--horizontal {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      margin-right: 16px;
    }

    &__mask {
      &--top {
        rotate: 180deg;
        z-index: 10;
        width: 100%;
        height: 27px;
        position: absolute;
        left: 0;
        top: -3px;
        background: linear-gradient(
          180deg,
          rgba(255, 212, 0, 0) 12.9%,
          rgba(255, 212, 0, 0.59) 64.39%,
          #ffd400 100%
        );
        pointer-events: none;
      }

      &--bottom {
        z-index: 10;
        width: 100%;
        height: 27px;
        position: absolute;
        left: 0;
        bottom: -3px;
        background: linear-gradient(
          180deg,
          rgba(255, 212, 0, 0) 12.9%,
          rgba(255, 212, 0, 0.59) 64.39%,
          #ffd400 100%
        );
        pointer-events: none;
      }
    }

    &__row {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 16px;

      &__item {
        width: 300px;
        border-radius: 8px;
        border: 1px solid #37444d;
        cursor: pointer;

        @media screen and (max-width: 768px) {
          width: 224px;
        }

        &:hover {
          box-shadow: 4px 6px 0px 0px rgba(122, 102, 0, 0.5);
        }
      }
    }
  }

  &__icon-left {
    position: absolute;
    top: 50px;
    left: 0;

    @media screen and (max-width: 600px) {
      left: -20px;
      top: 50px;

      img {
        width: 60px;
      }
    }
  }

  &__icon-right {
    position: absolute;
    top: 50px;
    right: 0;

    @media screen and (max-width: 600px) {
      right: -20px;
      top: 50px;

      img {
        width: 60px;
      }
    }
  }
}
