@import '../color-variables.scss';

.MintPage {
  width: 100%;

  &__leader-board {
    border-radius: 4px;
    border: 1px solid #333374;
    padding: 12px 8px;
    width: calc(100% - 16px);
    max-width: 1024px;
    margin-top: 64px;

    .infinite-scroll-component {
      overflow: hidden !important;
    }

    &__loading {
      color: var(--Neutral-Blue-0, #fff);
      text-overflow: ellipsis;
      /* Body Small/Bold */
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      text-align: center;
      padding: 10px 0;
    }

    &__list {
      max-height: 500px;
      height: auto;
      overflow-y: auto;
      &::-webkit-scrollbar {
        border-radius: var(--corner-radius-circular, 100px);
        background: var(--Overlay-W-5, rgba(255, 255, 255, 0.05));
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: var(--corner-radius-circular, 100px);
        background: var(--Overlay-W-10, rgba(255, 255, 255, 0.1));
        width: 4px;
      }
    }

    &__title {
      color: var(--Neutral-Grey-50, #fafafa);
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 20px;
      margin-left: 30px;
      margin-top: 10px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      color: var(--Yellow-200, #fdfae7);
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      height: 48px;
      align-items: center;
      border-bottom: 1px solid var(--Overlay-W-5, rgba(255, 255, 255, 0.05));

      & > div:first-child {
        display: flex;

        div {
          padding: 8px;
        }

        div:first-child {
          width: 72px;
          text-align: center;
        }

        div:last-child {
          @media screen and (max-width: 600px) {
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      & > div:last-child {
        padding: 8px;
      }
    }

    &__account {
      background-color: $Overlay-W-5;
      height: 44px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--Neutral-Blue-0, #fff);
      text-overflow: ellipsis;
      /* Body Small/Bold */
      font-family: 'SpaceMono-Bold', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;

      div:first-child {
        display: flex;
        gap: 16px;

        div:first-child {
          width: 72px;
          text-align: center;
          justify-content: center;
        }
      }
    }

    &__pagination {
      display: flex;
      justify-content: center;
    }
  }

  &__mint {
    position: relative;
    width: calc(100% - 96px * 2);
    padding: 95px 96px 56px 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 95px - 56px);

    @media screen and (max-width: 1439px) {
      gap: 0;
      padding: 56px 40px 0px 40px;
      width: calc(100% - 40px * 2);
      min-height: calc(100vh - 56px);
    }

    @media screen and (max-width: 767px) {
      padding: 88px 16px 56px 16px;
      width: calc(100% - 16px * 2);
    }

    &__burn__balance {
      color: #ffffff;
      /* Body XS/Regular */
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 5px;
      display: flex;
      justify-content: flex-end;
    }

    &__burn-input {
      padding: 10px 16px;
      border-radius: var(--Radii-radius-moderate, 8px);
      border: 1px solid var(--Neutral-Blue-300, #666693);
      width: calc(100% - 32px);

      &__label {
        color: var(--Neutral-Blue-200, #9999b3);
        font-family: 'SpaceMono-Regular', sans-serif;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;

        span {
          color: var(--Pink-500, #ff3979);
        }
      }

      &__field {
        display: flex;
        gap: 16px;
        align-items: center;

        div {
          color: var(--Neutral-Blue-200, #9999b3);
          /* Body XS/Regular */
          font-family: 'SpaceMono-Regular', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        .btn-max {
          padding: 5px 8px;
          border-radius: 6px;
          color: #ffffff;
          cursor: pointer;
          background-color: $Blue-500;
        }
      }

      input {
        background-color: transparent;
        border: 0;
        color: var(--Neutral-Blue-0, #fff);
        /* Action/Decor-lg */
        font-family: 'RetroGaming', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        text-transform: uppercase;
        outline: none;
        flex: 1;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }

    .MintPage__mint__main {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 64px;
      width: 100%;
      height: fit-content;

      @media screen and (max-width: 1439px) {
        gap: 0;
        align-items: center;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 48px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 1740px;
      max-width: calc(100% - 40px * 2);
      height: 686px;
      background-image: url('./../../assets/images/mint-background.png');
      opacity: 0.03;
      z-index: -1;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $Blue-800;
      z-index: -2;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    &__image__container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 1439px) {
        max-width: 601px;
      }

      @media screen and (max-width: 767px) {
      }

      &__img {
        height: auto;
        border-radius: 16px;
        width: 459px;

        @media screen and (max-width: 1439px) {
          width: 100%;
          max-width: 459px;
        }

        @media screen and (max-width: 767px) {
          max-width: 416px;
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
      margin: 24px;
      width: calc(495px - 24px * 2);
      min-width: unset;
      gap: 24px;
      height: fit-content;

      @media screen and (max-width: 1439px) {
      }

      @media screen and (max-width: 767px) {
        width: 100%;
        min-width: unset;
        max-width: 640px;
        padding: 0;
        margin: 0;
      }

      &__noti {
        color: #fff;
        font-family: 'SpaceMono-Regular', sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px; /* 150% */
        opacity: 0.5;
      }

      &__header {
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: flex-start;

        @media screen and (max-width: 1439px) {
          flex-direction: column;
          align-items: flex-start;
        }

        @media screen and (max-width: 767px) {
          flex-direction: row;
          align-items: center;
        }

        &__phase {
          color: #fff;
          font-family: 'RetroGaming', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 28.8px */
          letter-spacing: -0.24px;
          text-transform: uppercase;

          @media screen and (max-width: 767px) {
            font-size: 18px;
            letter-spacing: -0.18px;
          }
        }

        &__status {
          display: inline-flex;
          margin-left: 8px;
          height: 1em;
          position: absolute;
        }

        &__status--coming-soon {
          display: flex;
          padding: 2px 8px;
          border-radius: 4px;
          background: $Yellow-900;
          text-transform: uppercase;
          width: fit-content;

          color: $Yellow-500;
          font-family: 'SpaceMono-Regular', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px; /* 150% */
        }

        &__status--live {
          display: flex;
          padding: 2px 8px;
          align-items: flex-start;
          border-radius: 4px;
          background: $Green-900;
          text-transform: uppercase;
          width: fit-content;

          color: $Green-500;
          font-family: 'SpaceMono-Regular', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px; /* 150% */
        }

        &__status--completed {
          display: flex;
          padding: 2px 8px;
          align-items: flex-start;
          border-radius: 4px;
          background: $Neutral-Grey-800;
          text-transform: uppercase;
          width: fit-content;

          color: #f1f1f3;
          font-family: 'SpaceMono-Regular', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px; /* 150% */
        }
      }

      &__description {
        color: $Neutral-Blue-100;
        font-family: 'SpaceMono-Regular', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.8px;
        margin-top: -24px;

        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 20px; /* 142.857% */
        }
      }

      &__see-more {
        color: var(--Blue-400, #7070ff);
        text-align: center;
        /* Action/sm */
        font-family: 'SpaceMono-Bold', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 133.333% */
        text-transform: uppercase;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      &__about {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: $Blue-900;

        div:first-child {
          color: $Neutral-Blue-300;
          font-family: 'SpaceMono-Regular', sans-serif;
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: 14px; /* 140% */
        }

        div:nth-child(2) {
          color: #fff;
          font-family: 'SpaceMono-Regular', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          align-self: stretch;

          &.hide {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      &__countdown {
        &__box {
          display: flex;
          flex-direction: row;
          gap: 4px;
          justify-content: flex-start;
          align-items: center;

          color: #fff;
          font-family: 'SpaceMono-Regular', sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 133.333% */
        }

        &__box--live {
          color: #fff;
          font-family: 'SpaceMono-Regular', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px; /* 133.333% */
        }

        &__item {
          display: flex;
          justify-content: center;
          padding: 8px;
          align-items: flex-start;
          gap: 4px;
          border-radius: 4px;
          background: $Blue-700;
        }
      }

      &__countdown--coming-soon {
        display: flex;
        gap: 16px;
        flex-direction: column;

        @media screen and (max-width: 767px) {
          margin: 0 auto;
        }

        &__text {
          align-self: stretch;
          color: $Yellow-500;
          font-family: 'SpaceMono-Regular', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }

      &__countdown--live {
        display: flex;
        width: calc(100% - 16px * 2);
        padding: 12px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
        border-radius: 8px;
        background: $Blue-700;

        &__text {
          align-self: stretch;
          color: $Neutral-Blue-200;
          font-family: 'SpaceMono-Regular', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }

        &__end-in {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          justify-content: space-between;

          &__total-bid {
            color: $Neutral-Blue-50;
            text-align: right;
            font-family: 'SpaceMono-Regular', sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 140% */
          }
        }
      }

      &__mint-amount {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
      }

      &__mint-amount-box {
        display: flex;
        flex-basis: auto;
        padding: 16px;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        border-radius: 8px;
        border: 1px solid $Neutral-Blue-300;
        width: fit-content;

        color: #fff;
        text-align: center;
        font-family: 'SpaceMono-Regular', sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px; /* 150% */
        text-transform: uppercase;

        button {
          border: none;
          background-color: transparent;
          width: 20px;
          height: 20px;
          padding: 0;
          margin: 0;
          cursor: pointer;
        }
      }

      &__connect-wallet {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        width: 100%;

        &__btn {
          width: 100%;

          button {
            display: flex;
            padding: 16px 24px;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            border-radius: 8px;
            background: $Blue-500;
            border: none;
            cursor: pointer;

            &:disabled {
              cursor: not-allowed;
            }

            color: #fff;
            text-align: center;
            font-family: 'RetroGaming', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            text-transform: uppercase;

            @media screen and (max-width: 1439px) {
              padding-right: 0;
              padding-left: 0;

              font-size: 14px;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }

          &--sold-out {
            color: $Neutral-Blue-900;

            .btn-wrap__icon-left {
              color: red !important;
            }

            button {
              opacity: 0.5 !important;
              background: $Neutral-Blue-25;

              color: $Neutral-Blue-900;
            }
          }
        }

        &__warning {
          color: $Pink-500;
          text-align: center;
          align-self: stretch;
          font-family: 'SpaceMono-Regular', sans-serif;
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: 14px; /* 140% */
        }
      }

      &__not-whitelisted {
        display: flex;
        flex-direction: row;
        padding: 16px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: $Pink-900;
        color: $Pink-500;

        &__img {
          width: 20px;
          height: 20px;
        }

        &__text {
          font-family: 'SpaceMono-Regular', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }

  &__discover {
    background: $Blue-800;
    padding-top: 96px;
    padding-bottom: 192px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    pointer-events: none;
    user-select: none;
    overflow: hidden;

    img {
      display: block;
      width: 176px;
      border-radius: 16px;
    }

    &__gen {
      display: flex;
      flex-direction: row;
      gap: 24px;
      margin-right: 24px;
      overflow: hidden;
    }
  }

  .SlideLoop {
    &__container {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      width: 100vw;

      &__child1 {
        animation-duration: 10s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-name: slide-loop;
        animation-play-state: running;
      }

      &__child2 {
        animation-duration: 10s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-name: slide-loop;
        animation-play-state: running;
      }
    }
  }

  @keyframes slide-loop {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slide-loop-reverse {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
}

.MintSuccessModal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  position: relative;
  outline: none !important;

  &__close {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &__title {
    color: #f5f5ff;
    text-align: center;
    font-family: 'SpaceMono-Regular', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    align-self: stretch;
  }

  &__description {
    color: #f5f5ff;
    text-align: center;
    font-family: 'SpaceMono-Regular', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    align-self: stretch;
  }

  &__nfts {
    display: flex;
    padding: 24px 0px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 203px;
    align-self: stretch;
    width: calc(100% - 155px);

    &__marker {
      position: relative;
      width: 1px;
    }
  }

  &__action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &__check-my-wallet {
      display: flex;
      width: 140px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      background-color: transparent;
      flex: 1 0 0;
      border-radius: 8px;
      border: 1px solid #9999b3;
      color: #f5f5ff;
      text-align: center;

      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      text-transform: uppercase;
    }

    &__okey {
      display: flex;
      width: 140px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex: 1 0 0;
      border-radius: 8px;
      background: #ffa800;
      cursor: pointer;

      color: #f5f5ff;
      text-align: center;
      font-family: 'SpaceMono-Regular', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
      text-transform: uppercase;
    }
  }
}
