.SlideLoop {
  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    &__child1 {
      animation-duration: 10s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-name: slide-loop--horizontal;

      &:hover {
        animation-play-state: paused;
      }
    }

    &__child2 {
      animation-duration: 10s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-name: slide-loop--horizontal;

      &:hover {
        animation-play-state: paused;
      }
    }
  }
}

@keyframes slide-loop--horizontal {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-loop-reverse--horizontal {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-loop--vertical {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slide-loop-reverse--vertical {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
